/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-searchbar{
    --ion-background-color: white;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ion-page {
    overflow-y: scroll;
}

*{
    --ion-background-color:rgb(255, 240, 255);
}

ion-input, ion-select{
   // --background: rgba(255,255,255,0.4) !important;
}

input{
    //border: 1px dotted rgba(255,255,255,0.3) !important;
}

ion-footer ion-button {
    height: 3.5em;
    margin: 0px !important;
    --padding-bottom: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
    --padding-top: 0px;
}

ion-content{
    --padding-start: 5px;
    --padding-end: 5px;
}

.hover:hover {
    background-color: lightgrey;
}

.bold{
    font-weight: bold;
}

.underline{
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.right{
    text-align: right;
}

.full-width{
    width: 100%;
} 

.full-height{
    height: 100%;
} 

.center{
    text-align: center;
}

.no-space{
    margin: 0px;
    padding: 0px;
}

.transparent{
    background-color: transparent !important;
    --ion-background-color:transparent !important;
}

.white{
    background-color: white !important;
    --ion-background-color:white !important;
}

.info{
    font-size: small;
    font-style: italic;
    font-weight: 100;
}

@media only screen and (max-width: 700px) {
    ion-col{
        font-size: 0.85rem;
    }
  }